import React, { useState } from "react";
import ImageUploader from "react-images-upload";
import firebaseApp from "./firebase-app";
import { CookieBanner } from "@keepist/react-gdpr-cookie-banner";
import { Route, Routes } from "react-router-dom";
import Privacy from "./privacy";

// import Compressor from "compressorjs";
import { getAnalytics, logEvent } from "firebase/analytics";
import "./App.css";

const UploadComponent = (props) => (
  <form>
    <ImageUploader
      key="image-uploader"
      withIcon={true}
      singleImage={true}
      withPreview={true}
      label="Maximum size file: 5MB"
      buttonText="Choose an image"
      onChange={props.onImage}
      imgExtension={[".jpg", ".png", ".jpeg"]}
      maxFileSize={5242880}
    ></ImageUploader>
  </form>
);

const App = () => {
  const [progress, setProgress] = useState("getUpload");
  const [url, setImageURL] = useState(undefined);
  const [caption, setCaption] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState("");
  const analytics = getAnalytics();

  const onImage = async (failedImages, successImages) => {
    const url =
      "https://europe-west3-instagram-captions-1bf8f.cloudfunctions.net/lazyCaption";
    setImageURL(url);

    setProgress("uploading");

    try {
      // const img_64 = new Compressor(successImages[0].split(",")[1], {
      //   quality: 0.8,
      //   success: (compressedResult) => {},
      // });
      const img_64 = successImages[0].split(",")[1];
      const data = JSON.stringify({
        image: img_64,
      });
      const lazyCaption = firebaseApp.httpsCallable(
        firebaseApp.functions,
        "lazyCaption"
      );
      lazyCaption(data)
        .then((result) => {
          const data = result.data;
          setCaption(data);
          setImageURL(img_64);
          setProgress("uploaded");
        })
        .catch((error) => {
          console.log("error in upload", error);
          setErrorMessage(error.message);
          setProgress("uploadError");
        });
    } catch (error) {
      setErrorMessage(error.message);
      setProgress("uploadError");
    }
  };

  const content = () => {
    switch (progress) {
      case "getUpload":
        return <UploadComponent onImage={onImage} url={url} />;
      case "uploading":
        return <h2>Uploading....</h2>;
      case "uploaded":
        return (
          <>
            <img
              src={`data:image/jpeg;base64,${url}`}
              width="250"
              height={null}
              aspectRatio={135 / 76}
              alt="uploaded"
            />
            <h2>{caption}</h2>
          </>
        );
      case "uploadError":
        return (
          <>
            <div>Error message = {errorMessage}</div>
            <UploadComponent onImage={onImage} url={url} />
          </>
        );
    }
  };

  return (
    <div className="App">
      <h1>Lazy Caption</h1>
      <CookieBanner
        message="Cookie banner message"
        policyLink="/privacy"
        onAcceptPreferences={() => {
          // load your preferences trackers here
        }}
        onAcceptStatistics={() => {
          logEvent(analytics, "notification_received");
        }}
        onAcceptMarketing={() => {
          // load your marketing trackers here
        }}
      />
      <Routes>
        <Route path="/" element={content()} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </div>
  );
};

export default App;
