import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import config from "./config.json";

const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");


const app = initializeApp(config.firebaseConfig);
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LcM1-AiAAAAADx7vGMnLddtGw3-bixKlbKWFKF9"),
  isTokenAutoRefreshEnabled: true,
});
const auth = getAuth();
const firestore = getFirestore();
const functions = getFunctions(app, "europe-west3");

const isDevEnv = false; // process.env.NODE_ENV === "development";
if (isDevEnv) {
  console.warn(`WARNING: NODE_ENV=${process.env.NODE_ENV}`);
  connectAuthEmulator(auth, "http://localhost:9099");
  console.warn("WARNING: Auth emulator connected to http://localhost:9099");
  connectFirestoreEmulator(firestore, "localhost", 8081);
  console.warn(
    "WARNING: Firestore emulator connected to http://localhost:8081"
  );
  connectFunctionsEmulator(functions, "localhost", 5001);
}

const emulatorURL = "http://localhost:5001/in-tracks-baas/europe-west3/";
const prodURL =
  "https://europe-west3-instagram-captions-1bf8f.cloudfunctions.net/";
const functionsURL = isDevEnv ? emulatorURL : prodURL;

export default { firestore, auth, functions, functionsURL, httpsCallable };
